.widget {
    position: relative;

    &.active {
        .kpi-widget--container {
            border-color: var(--color-mojo-200);
        }
    }
}


.kpi-widget--container {
    // border-color: var(--color-gray-100);
    border-width: 2px;
    border-radius: 10px;
    padding: 10px 2px;
    width: 100%;
    height: 100%;
    cursor: pointer;


    & .kpi-widget-chart--wrap {
        width: 100%;
        height: 100%;
        padding-right: 10px;
    }

    & .kpi-widget--spinner {
        width: 100%;
        
        &:has( > .chakra-spinner) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        & > .chakra-spinner {  
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .kpi-widget--chart {
            width: 100%;
        }
    }

    & .kpi-widget--heading {
        font-family: var(--font-family-gotham-medium);
        font-size: var(--font-size-14);
        // color: var(--color-black-600);
        position: absolute;
        top: 15px;
        left: 22px;
        z-index: 3;
    }

    & .kpi-widget-card--wrap {
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }

    & .kpi-widget--card {
        // background-color: var(--color-white-100);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        box-shadow: none;

        & hr {
            border-color: var(--color-gray-100);
        }
    }

    & .kpi-card--header {
        text-align: center;
        padding: 5px 0;
    }

    & .kpi-card--heading {
        font-family: var(--font-family-gotham-medium);
        font-size: var(--font-size-8);
        // color: var(--color-black-500);
    }

    & .kpi-card--body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 0;
    }

    & .kpi-card--body-stack {
        align-items: center;
    }

    & .kpi-card--pos-avg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        p {
            font-family: var(--font-family-gotham-bold);
            font-size: var(--font-size-25);
            color: var(--color-green-500);
        }

    }

    & .kpi-card--neg-avg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        p {
            font-family: var(--font-family-gotham-bold);
            font-size: var(--font-size-25);
            color: var(--color-red-500);
        }

    }

    & .kpi-card--details {
        width: 100%;
        font-family: var(--font-family-gotham-book);
        font-size: var(--font-size-9);
        // color: var(--color-black-600);
    }

    & .kpi-card--detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p:nth-child(2) {
            font-family: var(--font-family-gotham-medium);
        }

    }

    & .kpi-card--button {
        button {
            padding: 5px;
        }
    }

    // Apexcharts Legend
    .apexcharts-legend {

        &:is(:empty) {
            right: 0px;
            position: absolute;
            left: 20px;
            top: 20px;
            min-height: 24px;
        }
    }

}

#apexcharts_investmentChart {

    .apexcharts-legend {
        top: -5px !important;

        .apexcharts-legend-series[rel="1"] {
            margin-left: 20px;
        }
    }
}