// Client Info Tab - Google Map - Visualization
.client-info {
    &--tab {
        width: 100%;
        height: 100%;
    }

    &--map {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 200px);
        overflow: visible;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        z-index: 1;

        & > div {
            width: 100%;
            height: 100%;
        }
    }
}

.client-profile {
    .client-profile-left + .client-profile-right {
        .client-info {
            &--map {
                min-height: auto;
            }
        }
    }

}