/* 
    Global Mojo Table Styles
*/
.ant-table-wrapper {

    thead.ant-table-thead {

        th.ant-table-cell {
            font-family: var(--font-family-gotham-book);
            font-size: var(--font-size-13);
            color: var(--color-mojo-600);
            text-transform: uppercase;
            background: var(--color-white-100);
            border: none;
        }
    }

    tbody.ant-table-tbody {

        tr.ant-table-row {
            border-radius: 10px;
            border-color: var(--color-gray-300);

            td.ant-table-cell {
                font-family: var(--font-family-gotham-book);
                font-size: var(--font-size-12);
                color: var(--color-black-700);
                background: var(--color-white-100);
                padding: 10px;
            }
        }

    }

    .ant-table-column-sorter-inner {
        .ant-table-column-sorter-down.active {
            color: var(--color-mojo-200);
        }

        .ant-table-column-sorter-up.active {
            color: var(--color-mojo-200);
        }
    }

    // Actions Column
    // .action-btn--save,
    // .action-btn--cancel,
    // .action-btn--edit,
    // .action-btn--delete {}

    .actions-btn-group {

        button {
            background: none;
            width: auto;
            height: auto;
            padding: 5px;
        }

        .action-btn--delete {
            color: var(--color-red-500);
        }
    }

    // .actions-editing-btn-group {
    //     .action-btn--save {}
    //     .action-btn--cancel {}
    // }
}


@media (max-width: 1100px) {
    .ant-table-wrapper {
        
        thead.ant-table-thead {

            th.ant-table-cell {
                padding: 5px 5px 5px 10px;

                &.ant-table-cell-fix-right.ant-table-cell-scrollbar {
                    padding: 0;
                    box-shadow: none;
                    
                    &::after {
                        display: none;
                    }
                }
                &.ant-table-cell-fix-left:not([aria-label="Location"]) {
                    position: relative !important;
                    left: auto !important;
                    z-index: 1;
                }
                &.ant-table-cell-fix-left:first-child::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -1px;
                    width: 30px;
                    transform: translateX(100%);
                    transition: box-shadow 0.3s;
                    content: "";
                    pointer-events: none;
                    box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
                }
                
                &.ant-table-cell-fix-right {

                    &::after {
                        position: absolute;
                        top: 0;
                        bottom: -1px;
                        left: 0;
                        width: 30px;
                        transform: translateX(-100%);
                        transition: box-shadow 0.3s;
                        content: "";
                        pointer-events: none;
                        box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
                    }
                    &:before {  
                        display: none;
                    }
                }
            }
        }

        tbody.ant-table-tbody {

            tr.ant-table-row {
                
                td.ant-table-cell {
                    padding: 5px 5px 5px 10px;
                    transition: none;
                    
                    &.ant-table-cell-fix-left:not(:first-child) {
                        position: relative !important;
                        left: auto !important;
                        z-index: 1;
                    }
                    &.ant-table-cell-fix-left:first-child::after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: -1px;
                        width: 30px;
                        transform: translateX(100%);
                        transition: box-shadow 0.3s;
                        content: "";
                        pointer-events: none;
                        box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
                    }
                    &.ant-table-cell-fix-right {

                        &::after {
                            position: absolute;
                            top: 0;
                            bottom: -1px;
                            left: 0;
                            width: 30px;
                            transform: translateX(-100%);
                            transition: box-shadow 0.3s;
                            content: "";
                            pointer-events: none;
                            box-shadow: inset -10px 0 8px -8px rgba(5, 5, 5, 0.06);
                        }                        
                    }
                }
            }
        }
        
    }

    .actions-editing-btn-group {

        button {
            font-size: var(--font-size-14);
            padding: 0 5px;
        }
    }
}

@media (max-width: 900px) {
    .goals-page--header,
    .budgets-page--header {
        flex-wrap: wrap;
    }

    .goals-header--left,
    .goals-header--right,
    .budgets-header--left,
    .budgets-header--right {
        width: 100%;
    }

    .goals-heading,
    .budgets-heading {
        margin-bottom: 1rem;
    }
}

@media (max-width: 768px) {

    .goals-header--right,
    .budgets-header--right {
        flex-direction: column;

        .location-select-wrapper {
            margin-bottom: 1rem;

            & > .chakra-select__wrapper {
                max-width: fit-content;
            }
        }

        .year-selector {
            margin-left: 0;
        }
    }
}