.home-page--header {
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-300);

    .home-header--left {
        align-items: center;

        .home-heading {
            font-size: var(--font-size-30);
            font-family: var(--font-family-gotham-bold);
        }
    }
}

.home-page--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    .home-card-stack {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            align-items: flex-start;
            width: 100%;
            gap: 1rem;
        }

    .home-card {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        border: 2px solid transparent;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: all 0.3s ease;

    .card-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font-family-gotham-book);
        font-size: 1.5rem;
    }

}


}