.datepicker-container {
    position: relative;
    font-family: var(--font-family-gotham-book);
    font-size: var(--font-size-14);
    height: 2.5rem;
    width: 16.25rem; // 260px
    border-radius: 7px;
    
    & .react-datepicker-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;

        & .react-datepicker__input-container {
            height: 100%;
            width: 100%;

            & .datepicker {
                padding: 0 40px 0 12px;
                border-radius: 7px;
                width: 100%;
                height: 100%;

                &:focus-visible {
                    outline: none;
                }
            }
        }
    }

    & .datepicker-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: var(--color-gray-500);
        background-color: transparent;
        height: 100%;
        border-radius: 0px;
    }
}

[data-theme='dark']{
    .datepicker-container {
        border: 2px solid white;
        background-color: #282828;

        & .datepicker {
            background-color: #282828;
        }

        // &::placeholder {
        //     color: var(--color-black-600);
        // }

    }
}

[data-theme='light'] {
    .datepicker-container {
        border: 2px solid var(--color-gray-300);
        background-color: white;

        &:focus-visible {
            z-index: 1;
            border-color: #3182ce;
            box-shadow: 0 0 0 1px #3182ce;
            outline: none;
    
            & .react-datepicker-wrapper {
                border: none;
            }
        }
    }
}
