.datatable-controls-integrations-list-datatable {
    width: 1037px !important;
    padding: 0;
}

.datatable-controls-integrations-list-datatable table.datatable {
    table-layout: fixed;
}

.datatable-controls {

    button {
        background: none;

        &:hover {
            background: var(--color-gray-200);
        }
    }
}