.admin-page--header {
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-300);

    .admin-header--left {
        align-items: center;

        .admin-heading {
            font-size: var(--font-size-30);
            font-family: var(--font-family-gotham-bold);
            // color: var(--color-black-600);
        }
    }
}

.admin-page--body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    .admin-nav--links {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        align-items: flex-start;
        width: 100%;
        gap: 1rem;
    }

    .admin-card--link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        border: 2px solid transparent;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: all 0.3s ease;

        &:hover {
            text-decoration: none;
            box-shadow: none;
            border-width: 2px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

            svg {
                color: var(--color-mojo-200);
            }
        }
    }

    .admin-card {
        border: none;

        .card-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--font-family-gotham-book);
            font-size: 1.5rem;
            font-weight: 600;
            // color: var(--color-black-600);
        }

        .admin-card-count {
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
        }

    }


}