.add-edit-services-form {
    .services-form {
        justify-content: space-between;
    }
    .input-group {
        flex-grow: 0.51;
    }
}

.chakra-editable {
    display: flex;
    flex-grow: 1;
}

.chakra-editable__preview {
    border: solid 1px lightgray;
    padding: 13px;
    flex-grow: 1;
    padding-bottom: 65px !important;
}

.chakra-editable__textarea {
  padding-bottom: 65px !important;
}