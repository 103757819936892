/* @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"); */
/* @import url("https://fonts.googleapis.com/css?family=Inter:400,700"); */
/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap") */
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
/* @import url("https://px.animaapp.com/62bb57e10dba03712a86d7e0.62bb57e24489aa31da6fb44f.yRUOoBm.hcp.png"); */

@font-face {
    font-family: "Gotham Book";
    font-style: normal;
    font-weight: 400;
    src: url("/public/fonts/gotham-htf-book.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham Medium";
    font-style: normal;
    font-weight: 500;
    src: url("/public/fonts/gotham-htf-medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham Bold";
    font-style: normal;
    font-weight: 700;
    src: url("/public/fonts/gotham-htf-bold.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: url("/public/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins SemiBold";
    font-style: normal;
    font-weight: 600;
    src: url("/public/fonts/Poppins-SemiBold.ttf") format("truetype");
}

:root {
    color-scheme: light dark;
    --black: #000000;
    --gray: #848182;
    --log-cabin: #231f20;
    --quill-gray: #d5d5d5;
    --granite-gray: #676767;
    --white: #ffffff;
    --light-white: #f8f8f8;

    --font-size-s: 13px;
    --font-size-m: 12px;
    --font-size-l: 16px;
    --font-size-xl: 22px;
    --font-size-xxl: 30px;
    --font-size-xxxl: 52px;

    --font-family-gotham_htf-bold: "Gotham HTF-Bold", Helvetica;
    --font-family-gotham_htf-book: "Gotham HTF-Book", Helvetica;
    --font-family-inter: "Inter", Helvetica;

    /* 2024 Redesign */
    --font-family-gotham-book: "Gotham Book", Helvetica;
    --font-family-gotham-medium: "Gotham Medium", Helvetica;
    --font-family-gotham-bold: "Gotham Bold", Helvetica;
    --font-family-poppins: "Poppins", Helvetica;
    --font-family-poppins-semibold: "Poppins SemiBold", Helvetica;
    
    --font-size-8: 0.5rem;
    --font-size-9: 0.5625rem;
    --font-size-11: 0.6875rem;
    --font-size-12: 0.75rem;
    --font-size-13: 0.8125rem;
    --font-size-14: 0.875rem;
    --font-size-15: 0.9375rem;
    --font-size-16: 1rem;
    --font-size-18: 1.125rem;
    --font-size-20: 1.25rem;
    --font-size-22: 1.375rem;
    --font-size-24: 1.5rem;
    --font-size-25: 1.5625rem;
    --font-size-30: 1.875rem;
    --font-size-80: 5rem;

    --color-mojo-50: #EAF0F4;
    --color-mojo-100: #DCE9EB;
    --color-mojo-200: #73CEE2;
    --color-mojo-300: #3aadad;
    --color-mojo-400: #04B4DB;
    --color-mojo-500: #1D97B2;
    --color-mojo-600: #0C7F98;

    --color-green-500: #0EE87B;
    --color-green-600: #38CB89;

    --color-yellow-500: #f09450;

    --color-orange-500: #F5900C;
    
    --color-red-500: #F20850;

    --color-white-100: #FFFFFF;
    --color-white-200: #F8F8F8;

    --color-gray-100: #E2E2E2;
    --color-gray-200: #E6E2DF;
    --color-gray-300: #D5D5D5;
    --color-gray-400: #838383;
    --color-gray-500: #676767;
    --color-gray-600: #5C5C5C;

    --color-purple-500: #43425D;
    --color-purple-600: #393335;

    --color-brown-500: #77686C;

    --color-black-100: #343A40;
    --color-black-200: #2B3035;
    --color-black-300: #23272B;
    --color-black-400: #1A1D20;
    --color-black-500: #393939;
    --color-black-600: #231F20;
    --color-black-700: #000000;
}

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    height: 140vh;
    width: 100%;
}

@media (max-width: 768px) {
    #root {
        height: auto;
    }
}

button.chakra-button {

    &:disabled:hover {
        background: var(--color-mojo-200);
        color: var(--color-white-100);
        opacity: 0.4;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        background: var(--color-gray-200);
        color: var(--color-gray-600);
    }
}

/* App Wrapper - direct child of #root */
.app-wrapper {
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

/* Page Layout - Includes margin for SideNavBar, and padding for page content */
.app-content--container {
    flex-direction: column;
    position: relative;
    margin: 0 0 0 70px;
    padding: 25px 25px 0px 30px;
    height: 100%;

    .chakra-select__wrapper {
        padding-right: 0;
    }

    .primary-btn {
        background-color: var(--color-mojo-200);
        color: var(--color-white-100);
        font-family: var(--font-family-gotham-book);
        font-size: var(--font-size-14);
        border-radius: 7px;
        padding: 10px;

        &:not(:disabled):hover {
            background: var(--color-gray-200);
            color: var(--color-gray-600);
        }
    }

    .add-user-with-clients-btn {
        background-color: var(--color-mojo-200);
        border-radius: 7px;
        font-family: var(--font-family-gotham-medium);
        font-size: var(--font-size-14);
        color: var(--color-white-100);
        padding: 10px;
        margin-left: 10px;
    }
}

@media (max-width: 1400px) {
    .app-content--container {
        padding: 15px 15px 30px 15px;
    }
}

img,
svg {
    width: 100%;
}

/* Form Ele/Component */
select,
select.chakra-select {
    border-color: var(--color-gray-300);
    border-radius: 7px;
    border: 2px solid var(--color-gray-300);
    font-size: var(--font-size-14);
    /* color: var(--color-black-600); */
}

.screen a {
    display: contents;
    text-decoration: none;
}

.off-canvas {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 0;
    width: 220px;
}

nav.off-canvas {
    background: rgb(35, 31, 32) !important;
}

.off-canvas svg {
    width: 100%;
}

.canvas {
    position: absolute;
    z-index: 1;
    top: 0;
    /*left: 105px;*/
    left: 75px;
    width: calc(100vw - 120px);
    min-height: 100vh;
    height: fit-content;
    background: var(--light-white);
    transform: translateX(0);
    transition: transform 0.2s ease-in;
}

.canvas.open {
    transform: translateX(150px);
}

.highcharts-credits {
    display: none;
}

/* outline override for src/components/CodeEditorModal.tsx */
.code-editor__textarea:focus {
    outline: none;
}
/* outline override for src/components/CodeEditorModal.tsx */


/* CSS Loader */
.loader,
.loader:after {
    border-radius: 50%;
    width: 4em;
    height: 4em;
}
.loader {
    margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.6em solid rgba(115, 206, 226, 0.2);
    border-right: 0.6em solid rgba(115, 206, 226, 0.2);
    border-bottom: 0.6em solid rgba(115, 206, 226, 0.2);
    border-left: 0.6em solid #73cee2;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
