.client-profile {
	display: flex;
	gap: var(--chakra-space-10);
	
	&:first-of-type{
		.client-profile-right {
			margin-left: 100px;
		}
	}

	&-left {
	}

	&-right {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: var(--chakra-space-5);
	}

	.item-menu,
	.item-menu-active {
		cursor: pointer;
	}

	.left-side {
		gap: 50px;
	}

	.left-header-wrapper {
		align-items: center;
		display: flex;
		width: fit-content;
	}

	.left-header-container {
		align-items: center;
		display: flex;
		gap: 33px;
		width: fit-content;
	}

	.bt-client-list {
		height: 40px;
		min-width: 124px;
	}

	.left-header-title {
		color: var(--log-cabin);
		/* font-family: var(--font-family-gotham_htf-bold); */
		font-size: var(--font-size-xl);
		font-weight: 700;
		letter-spacing: 0;
		line-height: normal;
		width: fit-content;
	}

	.bt-collapse {
		height: 13px;
		margin-right: -0.75px;
		min-width: 13px;
	}

	.left-form {
		gap: 19px;
	}

	.left-form-header {
		align-items: center;
		display: flex;
		gap: 381px;
		width: fit-content;
	}

	.left-form-title {
		color: var(--log-cabin);
		/* font-family: var(--font-family-gotham_htf-book); */
		font-size: var(--font-size-l);
		font-weight: 400;
		letter-spacing: 0;
		line-height: normal;
		width: fit-content;
	}

	.left-form-switchs {
		display: flex;
		align-items: center;
		gap: 6px;
		width: fit-content;
	}

	.group {
		height: 24px;
		min-width: 64px;
	}

	.input-group-1 {
		flex-direction: column;
		gap: 10px;
	}

	.input {
		align-items: flex-start;
		align-self: stretch;
		background-color: var(--white);
		border: 2px solid;
		border-color: var(--quill-gray);
		border-radius: 8px;
		display: flex;
		gap: 68px;
		padding: 16px 20px;
	}

	.input-label {
		letter-spacing: 0;
		line-height: normal;
		margin-top: -2px;
		width: fit-content;
	}

	.input-group-2 {
		gap: 20px;
	}

	.input-1 {
		align-items: flex-start;
		background-color: var(--white);
		border: 2px solid;
		border-color: var(--quill-gray);
		border-radius: 8px;
		display: flex;
		flex: 1;
		gap: 68px;
		padding: 16px 20px;
	}

	.right-side {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		gap: 16px;
		width: fit-content;
	}

	.submenu-wrapper {
		flex-direction: column;
		gap: 10px;
		border-bottom: 1px solid #ddd;
	}

	.submenu-container {
		align-self: stretch;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-color: var(--quill-gray);
		gap: 25px;
		padding: 0px 13px;
	}

	.item-menu {
		align-items: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 6px;
		border-color: transparent;
		display: flex;
		gap: 10px;
		height: 46px;
		padding: 10px;
		width: fit-content;
	}

	.menu-label {
		letter-spacing: 0;
		line-height: normal;
		white-space: nowrap;
		margin-top: -6px;
		width: fit-content;
	}

	.item-menu-active {
		align-items: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 6px;
		border-color: #73cee2;
		display: flex;
		gap: 10px;
		height: 46px;
		padding: 10px;
		width: fit-content;
	}

	.menu-label-active {
		color: var(--log-cabin);
		/* font-family: var(--font-family-gotham_htf-bold); */
		font-size: var(--font-size-l);
		font-weight: 700;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -6px;
		width: fit-content;
	}

	.form-upper-wrapper {
		align-items: flex-start;
		background-color: var(--white);
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		filter: drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.102));
		padding: 15px 20px 20px;
	}

	.form-upper {
		padding: 10px;
	}

	.form-upper-title {
		color: var(--log-cabin);
		/* font-family: var(--font-family-gotham_htf-book); */
		font-size: var(--font-size-l);
		font-weight: 400;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -1px;
		width: fit-content;
	}

	.input-group {
		align-items: flex-start;
		align-self: stretch;
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 128.86px;
	}

	.text-1 {
		color: var(--white);
		/* font-family: var(--font-family-inter); */
		font-size: var(--font-size-xxl);
		font-weight: 400;
		letter-spacing: 0;
		line-height: normal;
		width: fit-content;
	}

	.form {
		align-items: flex-start;
		align-self: stretch;
		display: flex;
		gap: 10px;
	}

	.input-group-3 {
		align-items: flex-start;
		display: flex;
		width: 650px;
	}

	.left {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: fit-content;
	}

	.submenu {
		align-items: flex-start;
		display: flex;
	}
}
