// Uploader Container
.uploader--container {
    border: 2px solid var(--color-gray-300);
    border-radius: 7px;
    width: 100%;
    padding: 15px;
}

.upload-progress--container {
    width: 100%;
    margin-top: 1.25rem; // 20px
    margin-bottom: 1.875rem; // 30px;
    align-items: center;

    .progress-text {
        font-size: var(--font-size-14);
        font-family: var(--font-family-gotham-bold);
        color: var(--color-gray-400);
        text-transform: uppercase;
    }

    .progress-bar {
        width: 100%; // fallback, prop most remain on Compnent
        margin-left: 10px;
        border-radius: 40px;
    }

    .progress-uploading-indicator-2 {
        font-family: var(--font-family-gotham-medium);
        font-size: var(--font-size-14);
        color: var(--color-gray-300);
    }
}

.uploader-preview--container {

    .preview-content {

        .preview-heading {
            font-size: var(--font-size-22);
            font-family: var(--font-family-gotham-bold);
            color: var(--color-black-600);
            margin-bottom: 1.25rem; // 20px
        }
    }

    .uploader-preview--footer {
        justify-content: space-between;

        button {
            background-color: var(--color-mojo-200);
            border-radius: 7px;
            font-family: var(--font-family-gotham-medium);
            font-size: var(--font-size-14);
            color: var(--color-white-100);

            // &.reset-button {}
    
            // &.complete-upload-button {}
        }
    }
}


