.goals-table {
    width: 100%;
    border-top: 1px solid var(--color-gray-300);

    .ant-table-tbody>tr:last-child>td {
            font-weight: bold;
        }

    .table-header {
        margin: 15px 0;
        justify-content: flex-end;

        .add-row--btn {
            margin-right: 15px;
        }
    }

    .notes-column {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
        display: inline-block;
    }

    .data-toggle--wrapper {
        justify-content: center;
        align-items: center;
        margin-right: 15px;

        .chakra-switch {
            position: relative;
        }

        // Toggle Switch for Actual vs Goals Data
        #actualGoalsDataToggle {
            height: 30px;
            display: flex;
            align-items: center;
            max-width: 80px;

            &.ant-switch-checked {
                background: var(--color-mojo-200);
            }

            .ant-switch-handle {
                top: 50%;
                transform: translateY(-50%);
            }

            .ant-switch-inner {
                display: flex;
                align-items: center;
                font-family: var(--font-family-gotham-book);
                font-size: var(--font-size-13);

                & .ant-switch-inner-checked {
                    margin-inline-end: calc(100% - 58px + 48px);
                }
            }

            .ant-switch-inner-unchecked {
                margin-top: 0;
            }
        }
    }

}