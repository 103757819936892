/* screen - datatable-controls */

.datatable-controls img,
.datatable-controls svg {
}

.datatable-controls {
    border: 1px none;
    display: flex;
    flex-direction: column;
}

.datatable-controls.no-flex {
    display: block;
}

.datatable-controls.no-gap {
    gap: 0;
}

.datatable-controls.no-padding {
    padding: 0;
}

.datatable-controls .table-filters-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 19px;
    width: fit-content;
    justify-content: space-between;
}

.datatable-controls .table-header {
    justify-content: space-between;
    width: 100%;
}

.datatable-controls .title-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    padding: 10px;
    width: fit-content;
}

.datatable-controls .title {
    color: #231f20;
    font-size: var(--font-size-xl);
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    width: fit-content;
}

.datatable-controls .action-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
}

.datatable-controls .bt-add-client {
    height: 40px;
    min-width: 124px;
}

.datatable-controls .filters-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.datatable-controls .table-filters {
    gap: 21px;
}

.datatable-controls .filter-delete-me-1 {
    flex: 1;
    height: 45px;
    min-width: 357px;
}


.datatable-controls .table-filters-item {
    height: 45px;
    min-width: 128px;
}

.datatable-controls .filter-delete-me-2 {
    height: 45px;
    min-width: 167px;
}

.datatable-controls .table-bottom-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.datatable-controls .pagination-wrapper {
    align-items: center;
    gap: 28px;
    width: fit-content;
}

.datatable-controls .bt-pagination-navigation {
    cursor: pointer;
    color: #73cee2;
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    width: fit-content;
}

.datatable-controls .bt-pagination-navigation.disabled {
    color: var(--shady-lady);
    cursor: auto;
}


.datatable-controls .navbar {
    align-items: flex-start;
    display: flex;
    gap: 15px;
    width: fit-content;
}

.datatable-controls .navbar-link-bt-pagination-current {
    color: #484546;
    font-size: var(--font-size-m);
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    width: fit-content;
}

.datatable-controls .navbar-link-bt-pagination {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    width: fit-content;
}

.datatable-controls .table {
    align-items: center;
    display: flex;
}
