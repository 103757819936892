.min-width-20 {
  min-width: 20ch;
}

.data-table-show-hide-columns-toggle-button {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  outline: transparent solid 2px;
  outline-offset: 2px;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  font-size: var(--chakra-fontSizes-xs);
  font-weight: var(--chakra-fontWeights-medium);
  background: var(--chakra-colors-white);
  color: var(--chakra-colors-black);
  padding-inline-start: var(--chakra-space-2);
  padding-inline-end: var(--chakra-space-2);
  padding-top: var(--chakra-space-1);
  padding-bottom: var(--chakra-space-1);
  margin: 0px;
  border-radius: var(--chakra-radii-md);
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: var(--chakra-colors-gray-300);
  height: var(--chakra-sizes-9);
}


.data-table-show-hide-columns-dropdown > div {
  transform: translateY(-9px);
}

