.services-list {
    display: flex;
    flex-direction: column;
    height: 175px;
    flex-wrap: wrap;
    // &-body {
    //     :first-child {
    //         grid-column: 1 !important;
    //     }
    //     .notes {
    //         grid-column: 1 / span 2;
    //     }
    // }
}

.items-left,
.icons-right {
    display: flex;
    align-items: center;
}

.items-left {
    // justify-content: flex-start;
    flex-grow: 1;

    input {
        margin-left: 16px;
        margin-right: 16px;
    }
}

.icons-right {
    justify-content: flex-end;
}
