.mojo-top-bar {
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 3px 4px #00000010;


    & .mojo-top-bar-content {
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    & .mojo-top-bar-left {
        position: relative;
        align-items: center;

        & .mojo-logo-wrapper {
            position: relative;
            padding-right: 30px;

            &::after {
                content: '';
                width: 1px;
                height: auto;
                background-color: var(--color-mojo-100);
                padding: 24px 0;
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            & .branding-logo {
                height: 80px;
                width: auto;
            }
        }
    }

    & .mojo-top-bar-right {
        align-items: center;
        margin-right: 2.5rem;

        & .organization-select {
            height: 2.5rem;
            width: 20.625rem;
            border-color: var(--color-gray-300);
            border-radius: 7px;
            border: 2px solid var(--color-gray-300);
            font-size: var(--font-size-14);
            // color: var(--color-black-600);

            &::placeholder {
                // color: var(--color-black-600);
            }
        }

        & .top-bar-right-actions {
            align-items: center;
        }
    }


}
