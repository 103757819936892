// Upload Form
.goals-upload--form {
    .goals-upload--header {
        align-items: center;
        margin-bottom: 1.875rem; // 30px

        .goals-upload--back-btn {
            font-size: var(--font-size-14);
            font-family: var(--font-family-gotham-book);
            padding: 0;
            margin-right: 1.875rem; // 30px
        }
        .goals-upload--heading {
            font-size: var(--font-size-22);
            font-family: var(--font-family-gotham-bold);
            color: var(--color-black-600);
        }
    }
}
