.add-edit-presentations-form {
    // &-body {
    //     :first-child {
    //         grid-column: 1 !important;
    //     }
    //     .notes {
    //         grid-column: 1 / span 2;
    //     }
    // }

    // input::-webkit-file-upload-button {
    //     display: none;
    // }
    // input::file-selector-button {
    //     display: none;
    // }

    .presentation-upload {
      flex-grow: 1;
    }

    .file-input {
      display: none;
    }
}
