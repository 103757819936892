// .main-chart--container | Top level 
.main-chart--container {
    position: relative;
    border-radius: 10px;

    & .main-chart--heading {
        font-family: var(--font-family-gotham-bold);
        font-size: var(--font-size-25);
        padding-top: 10px;
        // color: var(--color-black-500);
        display: flex;
        width: 100%;
        padding-left: 17px;
        // position: absolute;
        // top: 30px;
        // left: 0;
        // z-index: 4;
    }

    & .main-chart--col {
        width: 100%;
        height: 100%;
        justify-content: flex-end;
    }

    // Wrapper for chart component
    & .main-chart--wrapper {
        width: 100%;
        height: 100%;
        margin-right: 15px;

        // Each Chart will have an ID specified in the chart object. 
        // However, use the main-chart class for consistent styling.

        & .main-chart--spinner {
            align-items: center;
            justify-content: center;
            width: 100%;
            height:500px;
        }

        & .main-chart {
            width: 100%;
            min-height: 498px !important;

            & .apexcharts-canvas {
                max-height: 500px !important;
            }
        }
    }


    .main-chart-card--col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        & .main-chart--card {
            width: 100%;
            border-width: 1px;
            border-radius: 10px;
        }
    }

}

.main-chart--card {

    & .chart-card--header {
        padding-bottom: 0px;
        text-align: center;
    }

    & .chart-card--title {
        font-family: var(--font-family-gotham-medium);
        font-size: var(--font-size-18);
        padding-bottom: 15px;
    }

    & .chart-card--body {

        & .chart-card-kpi {
            position: relative;
            width: 100%;
            justify-content: center;
            padding-bottom: 80px;

            &.positive {
                color: var(--color-green-500);
            }

            &.negative {

                & .chart-card-kpi--icon {
                    color: var(--color-red-500);
                }
            }
        }

        & .chart-card-kpi--pos-value {
            font-family: var(--font-family-gotham-bold);
            font-size: var(--font-size-80);
            color: var(--color-green-500);
        }

        & .chart-card-kpi--neg-value {
            font-family: var(--font-family-gotham-bold);
            font-size: var(--font-size-80);
            color: var(--color-red-500);
        }

        & .chart-card-kpi--arrow {
            display: flex;
            justify-content: flex-end;
            margin-top: 2px;

            & .chart-card-kpi--icon {
                position: absolute;
                right: 0;
                bottom: 0;
                transform: rotate(45deg);
                width: 5rem;
                height: 5rem;
            }
        }

        & .chart-card-kpi-data {
            font-family: var(--font-family-gotham-book);
            font-size: var(--font-size-14);
            flex-direction: column;
            width: 100%;

            & .kpi-data-item {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }

            & .kpi-data-item--label {
                font-family: var(--font-family-gotham-book);
                font-size: var(--font-size-14);
            }

            & .kpi-data-item--value {
                font-family: var(--font-family-gotham-medium);
                font-size: var(--font-size-14);
            }
        }

        & .chart-card-kpi-footer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;


            & button {
                font-family: var(--font-family-gotham-medium);
                font-size: var(--font-size-14);
                color: var(--color-white-100);
                background-color: var(--color-mojo-200);
                padding: 10px 15px;
            }
        }

    }
}