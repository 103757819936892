.datatable-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
}
.datatable-main {
  display: flex;
  flex-direction: column;
  gap: var(--chakra-space-4);
}
.datatable-loading {
  flex: 1;
  display: grid;
  place-content: center;
}

.client-title-navigation-action-link {
  color:#73CEE2;
  cursor: pointer;
}

.client-title-navigation-action-link:hover {
  color:#231f20;
}