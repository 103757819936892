// dashboard-container
// dashboard-header
// dashboard-heading
// dashboard-filters
// dashboard-filter-company
// dashboard-filter-department
// dashboard-filter-source

.dashboard-container {

    & .dashboard-header {
        display: flex;
        justify-content: space-between;
        
        & .dashboard-heading {
            font-size: var(--font-size-30);
            // color: var(--color-black-600);
        }
    }

}

.dashboard-filters {
    margin-bottom: 2.4375rem;
    justify-content: flex-end;

    .spinner-container {
        width: 18.75rem;
    }

    & > div {
        display: flex;
        flex: 0 1 auto;
    }

    & .dashboard-filter {
        font-size: var(--font-size-14);
        // border: 2px solid var(--color-gray-300);
        border-radius: 7px;
        // color: var(--color-black-600);
    }

    .spinner-container {
        justify-content: flex-end;

        & > .chakra-select__wrapper {
            width: 100%;
        }
    }
}

.dashboard-layout {
    display: flex;
}

.widget-column {
    display: flex;
    flex-direction: column;
    width: 25%;

    .widget {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.chart-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 75%;
}

@media (max-width: 1600px) {
    .dashboard-header {
        flex-direction: column;
        align-items: flex-start;

        .dashboard-heading {
            margin-bottom: 1rem;
        }

        .dashboard-filters {
            width: 100%;
            justify-content: flex-start;
        }
    }

    .dashboard-layout {

        .widget-column {
            width: 30%;
        }

        .chart-column {
            width: 80%;
        }
    }
}

@media (max-width: 1280px) {
    .dashboard-header {

        .dashboard-filters {
            flex-wrap: wrap;

            & > div {
                flex: 1 0 45%;
                height: 2.5rem;
            }

        }
    }

    .dashboard-layout {
        flex-direction: column;

        .widget-column {
            order: 2;
            width: 100%;
            margin-top: 1.875rem;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .widget {
                display: flex;
                flex: 0 0 48%;
                width: 48%;
                margin: 0 1rem 1rem 0;

                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }

        .chart-column {
            order: 1;
            width: 100%;
        }

        .main-chart--card {

            .chart-card--body {

                .chart-card-kpi {
                    
                    p {
                        font-size: 4rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .dashboard-layout {

        .chart-column {
            .main-chart--container {
                .main-chart--col {
                    flex-direction: column;
                    
                    .main-chart--card {
                        .chart-card--body {
                            .chart-card-kpi {
                                p {
                                    font-size: 3.5rem;
                                }
                            }
                        }
                    }
                }

            }

        }
        
        .widget-column {
            flex-direction: column;

            .widget {
                flex: 1 1 auto;
                width: 100%;
                margin: 0 0 1rem 0;
            }
        }
    }

}

