.agency-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.agencies-page--header {
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-gray-300);

  .agencies-header--left {
    align-items: center;

    .agencies-heading {
      font-size: var(--font-size-30);
      font-family: var(--font-family-gotham-bold);
      color: var(--color-black-600);
    }
  } 
}