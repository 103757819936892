.client-detail-detail h5 {
    font-size: 13px;
    color: #0C7F98;
    border-bottom: solid 1px lightgrey;
    padding-bottom: 5px;
}

.presentations-files-list {
    // &-body {
    //     :first-child {
    //         grid-column: 1 !important;
    //     }
    //     .notes {
    //         grid-column: 1 / span 2;
    //     }
    // }
    .chakra-card {
      margin-bottom: 15px;
    }
    .chakra-card__body {
      display: flex;
      justify-content: space-between;
    }
    .chakra-link {
        font-size: 12px;
        color: #388ea0;
        margin-left: 16px;
    }
    .icons-right {
      :not(:first-child) {
        margin-left: 20px;
      }

      .chakra-icon {
        cursor: pointer;
      }  
    }
}
