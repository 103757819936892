table.datatable {
    width: 100%;
    filter: drop-shadow(0px 1px 4px rgba(21, 34, 50, 0.102));

    border-radius: 10px;
}

table.datatable .order-icon {
    min-width: 10px !important;
}

table.datatable .go-to-page {
    max-width: 80px;
}

table.datatable .sorting-wrapper {
    display: flex;
    gap: 15px;
    flex-direction: row;
    align-items: center;
}



.datatable-wrapper .filter-length-wrapper {
    max-width: 150px;
}

.table-filters input[type="text"],
.table-filters select {
    min-width: 190px;
}

table.datatable th,
table.datatable td {
    padding: 5px 10px 5px 15px;
    border-bottom: 1px solid #E5E5E5;    
}

table.datatable thead th {
    /* font-family: 'Gotham HTF', sans-serif; */
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    text-align: left;
    padding: 15px;
    border-bottom: 2px solid #E5E5E5;
}

table.datatable td {
    /* font-family: 'Inter', sans-serif; */
    font-style: normal;
    font-weight: 500!important;
    font-size: 13px;
    line-height: 17px;
    
}


table.datatable tfoot tr td {
    padding: 10px;
}

table.datatable tr {
    height:50px
}