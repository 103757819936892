.client-form {
    display: grid;
    grid-template-rows: 1fr;
    gap: var(--chakra-space-5);

    &-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    &-subheader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto repeat(2, minmax(0, 1fr));
        gap: var(--chakra-space-5);

        :first-child {
            grid-column: 1 / -1;
        }

        :nth-child(2) {
            grid-column: 1 / -1;
        }

        :nth-child(3) {
            grid-column: 1 / -1;
        }

        :nth-child(4) {
            grid-column: 1 / -1;
        }

        :nth-child(5) {
            grid-column: 1 / -1;
        }

        & .client-address-autocomplete {
            grid-column: 1 / -1;

        }
        
        label {
            color: var(--black);
            background-color: var(--white);
        }
    }


    .client-group {
        width: 100%;
    }

}

.full-width-grid-child {
    grid-column: 1 / -1;
    width: 100%;
}

.full-width-grid-child.extra-margin {
    margin: 16px 0px;
}

.exclude-from-grid {
    grid-column: span 2;
    gap: 8px;
    align-items: self-start;
    margin-top: -86px;
}

.exclude-from-grid>div:nth-child(1)>div:nth-child(1) {
    height: 41px;
}

.exclude-from-grid>div:nth-child(1)>div:nth-child(2) {
    height: 52px;
}

.exclude-from-grid>div:nth-child(2) {
    vertical-align: bottom;
    height: 205px;
}

.chakra-form-control input[name="address"] + label{
    transform: scale(0.85) translateY(-24px);
}

.client-state-container {
    label {
        background-color: var(--white);
        transform: scale(0.85) translateY(-20px);
    }
}

.client-brands-container {
    label {
        color: var(--black);
        background-color: var(--white);
    }

    #myMulti {

        & div:first-child:not([role="button"]) {
            padding-left: 8px; 
            min-height: 45px;

            & > span {
                color: #2D3748;
                font-size: 14px;
                background-color: #E2E8F0;
                border-radius: 4px;
                padding-right: 6px;

                div[role="button"] {
                    // color: #4A5568;
                    color: var(--black);
                    border-radius: 0;

                    &:hover {
                        background-color: #CBD5E0;
                        color: #2D3748;
                    }

                    > svg {
                        font-size: 14px;
                        color: var(--black);
                    }
                }
            }

            
        }
        
        svg {
            color: var(--black);
        }

    }
}


.client-pma-container {
    display: flex;
    width: 100%;
    grid-column: 1 / -1;
    font-family: var(--font-family-gotham-book);

    > label.chakra-form__label {
        background-color: var(--white);
        font-size: var(--chakra-fontSizes-xs);
        color: var(--black);
    }

    #pmaZipCodes {
        display: flex;
        width: 100%;

        &:focus-visible {

            > div {
                z-index: 1;
                border-color: #3182ce;
                box-shadow: 0 0 0 1px #3182ce;
            }
        }
        
        > div {
            width: 100%;
            border-width: 2px;
            border-color: var(--color-gray-300);
            border-radius: var(--chakra-radii-md);
            
            // &:focus-visible {
            //     z-index: 1;
            //     border-color: #3182ce;
            //     box-shadow: 0 0 0 1px #3182ce;
            // }

            input {
                font-family: var(--font-family-gotham-book) !important;
                font-size: var(--font-size-13) !important;
            }
        }
    }

    svg {
        color: var(--black);
    }
}

.client-type-container {
    label {
        background-color: var(--white);
    }   
}
