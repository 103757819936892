.users-page--header {
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-300);

    .users-header--left {
        align-items: center;

        .users-heading {
            font-size: var(--font-size-30);
            font-family: var(--font-family-gotham-bold);
            color: var(--color-black-600);
        }
    }

    .users-header--right {

        .add-user-with-clients-btn {
            background-color: var(--color-mojo-200);
            border-radius: 7px;
            font-family: var(--font-family-gotham-medium);
            font-size: var(--font-size-14);
            color: var(--color-white-100);
            padding: 10px;
            margin-left: 10px;
        }
    }
}